import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/reset.css';
import './css/style.css';

const TermsComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.id = "termly-jssdk";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Header */}
      <section className="internal-header-section">
        <div className="internal-header-content">
          <div className="internal-header-wrapper">
          <header className="header">
              <div className="logo-wrapper">
                <Link to="/demo"><img src="sg-logo.png" alt="Standard Giving Co. Logo" className="logo" /></Link>
              </div>
              <nav className="nav-wrapper">
                <div className="nav-links">
                  <Link to="/demo" className="nav-item">Home</Link>                
                  <a href="mailto:partners@standardgiving.com" className="nav-item">Contact Us</a>
                </div>
              </nav>
            </header>
            <h1 className="internal-header-title">Terms of Use</h1>
          </div>
        </div>
      </section>

      {/* Terms Section */}
      <section className="terms-section">
        <div className="terms-content">
          <div className="terms-txt" name="termly-embed" data-id="8dccf308-88fc-4887-af04-32e564b92420"></div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <img src="sg-logo-symbol.png" alt="sg-logo-symbol" className="footer-logo-symbol" />
        <div className="footer-content">
          <div className="footer-logo-section">
            <img src="sg-inverted.png" alt="Standard Giving Co. Logo" className="footer-logo" />
            <p className="footer-tagline">Helping people can be hard.<br />Standard Giving Co. makes it easy.</p>
          </div>
          <div className="footer-links">
            <div className="footer-link-column">
              <a href="mailto:partners@standardgiving.com" className="footer-link">Become a Partner</a>
              <a href="mailto:partners@standardgiving.com" className="footer-link">Contact Us</a>
            </div>
            <div className="footer-link-column">
              <Link to="/terms" className="footer-link">Terms of Use</Link>
              <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p className="footer-copyright">© 2024 Standard Giving Co. All Rights Reserved</p>
        </div>
      </footer>
    </div>
  );
};

export default TermsComponent;
